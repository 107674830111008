.legend-panel {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  width:100px;
  flex-direction: column;
  align-items: center;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  margin-right: 0.75rem;
  max-height: calc(100% - 115px);
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  background-color: #F5F5F5;
  border: 1px solid #e2e2e2;
  overflow: auto;
  z-index: 1001;
  transform: translateX(0);
  transition: transform 0.5s ease;
}
.legend-panel.off-screen {
  /* Doesn't matter how right it goes really, as long as its off the screen */
  transform: translateX(calc(150px));
}

.layer-button {
  position: absolute;
  top: 0.75rem;
  right: calc(95px + 1.5rem);
  background-color: white;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid #e2e2e2;
  z-index: 1001;
  transform: translateX(0);
  transition: transform 0.5s ease;
}
.layer-button.off-screen {
  transform: translateX(calc(100px + 0.5rem));
}
@media (max-width: 768px) {
  .layer-button {
    top: 3.5rem;
  }
}