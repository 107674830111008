@import "@arcgis/core/assets/esri/themes/light/main.css";

html,
body,
#root,
.mapDiv,
.App {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
body {
  margin: 0;  
  font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* TODO: temporary - taken from qld health guide https://www.design-system.health.qld.gov.au/ */

.accordion-button {
  color:#09549F;
  border: 1px solid #EBEBEB;
}
.accordion-button:focus {
  box-shadow: none;
}
.accordion-button::after {
  color: #008635;
}
.accordion-button:not(.collapsed) {
  background-color:#F5F5F5;
  box-shadow: none;
  text-decoration: underline;
  text-decoration-color:#3F7AB4;
  text-decoration-thickness: 1px;
  text-underline-offset:0.3em;
}

.accordion-item {
  border-radius: 0;
  background-color:#F5F5F5;
}
.accordion-item .accordion-body {
  background-color: #fff;
}
.accordion-item:first-of-type .accordion-button {
  border-radius: 0;
  text-decoration: underline;
  text-decoration-color:#3F7AB4;
  text-decoration-thickness: 1px;
  text-underline-offset:0.3em;
}
.accordion-item .accordion-button.collapsed {
  border-radius: 0;
  background-color:#F5F5F5;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0;
  text-decoration: underline;
  text-decoration-color:#3F7AB4;
  text-decoration-thickness: 1px;
  text-underline-offset:0.3em;
}