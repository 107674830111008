.side-panel {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  margin-left:0.75rem;
  max-height: calc(100% - 1.5rem);
  width: 305px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  background-color: #F5F5F5;
  overflow: auto;
  z-index: 1001;
  transform: translateX(0);
  transition: transform 0.65s ease; 
}
.side-panel.off-screen {
  transform: translateX(-350px);/* Doesn't matter how left it goes really, as long as its off the screen */
}
.toggle-button {
  position: absolute;
  top: 0.75rem;
  left: calc(300px + 1.5rem);
  background-color: white;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid #e2e2e2;
  z-index: 1001;
  transform: translateX(0);
  transition: transform 0.65s ease;
}
.toggle-button.off-screen {
  transform: translateX(calc(-300px - 0.75rem));
}
.toggle-arrow {
  transition: transform 0.65s ease;
}
.toggle-arrow.rotate {
  transform: rotate(180deg);
}
