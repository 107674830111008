$theme-colors: (
  "primary": #09549F,
  "secondary": #008635,
  "info" : #F5F5F5,
  "dark-blue": #05325F,
  "sapphire-blue": #09549F,
  "light-blue": #0085B3,
  "light-green": #6BBE27,
  "dark-green": #008635,
  "golden-yellow": #FFE500,
  "traditional-maroon": #771E32,
  "modern-maroon": #A70240
);
@import '~bootstrap/scss/bootstrap';
@import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
